@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --nord_polar0_1: #2E3440;
  --nord_polar0_2: #3B4252;
  --nord_polar0_3: #434C5E;
  --nord_polar0_4: #4C566A;

  --nord_aurora_1: #BF616A;
  --nord_aurora_2: #D08770;
  --nord_aurora_3: #EBCB8B;
  --nord_aurora_4: #A3BE8C;
  --nord_aurora_5: #B48EAD;

  --nord_snowstorm_1: #D8DEE9;
  --nord_snowstorm_2: #E5E9F0;
  --nord_snowstorm_3: #ECEFF4;
  
  --nord_frost_1: #8FBCBB;
  --nord_frost_2: #88C0D0;
  --nord_frost_3: #81A1C1;
  --nord_frost_4: #5E81AC;
}

.app {
  text-align: center;
  background-color: var(--nord_polar0_1);
  /* 100vh means 100% of the viewport */
  min-height: 100vh;
}

.app-device_connect_status {
  color: var(--nord_aurora_2);
}

@media (max-width: 650px) {
  .app-main {
    flex-direction: column;
  }
}

.app-main {
  color: var(--nord_aurora_3);
}

.app-main-monitor {
  min-width: 300px;
}

.app-main-control {
  min-width: 300px;
}

.app-footer {
  color: var(--nord_frost_3);
}